import { Link } from "gatsby"
import React from "react"
import { Container, Row, Col } from 'reactstrap';
import { AwesomeButton } from "react-awesome-button";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons'
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons'

import Mainform from "../components/mainform"


class SectionFoot extends React.Component {
  constructor(props) {
    super(props);
    this.card = this.card.bind(this);
  }


  componentDidMount() {
    var toggler = document.querySelector('.card-content');
    var togglerform = document.querySelector('.form-content');
    if(this.props.pagename === 'kontakt') {
      togglerform.classList.add('active');
      toggler.classList.add('disabled');
  }
  }


  card(event) {
       event.preventDefault();
      var toggler = document.querySelector('.card-content');
      var togglerform = document.querySelector('.form-content');
      var togglercard = document.querySelector('.get-started');

        if (toggler.classList.contains('disabled')) {
          toggler.classList.remove('disabled');
          return;
        }

        if (togglerform.classList.contains('active')) {
          togglerform.classList.remove('active');
          return;
        }
        if (togglercard.classList.contains('changed')) {
          togglercard.classList.remove('changed');
          return;
        }
        toggler.classList.toggle('disabled');
        togglerform.classList.toggle('active');
        togglercard.classList.toggle('changed');
    }

    render() {
      let lang =  this.props.lang;
      if(lang === 'en') {
        return (
          <div className="footer-container">
          <Container>
            <div className='get-started'>
              <div className='shape1'></div>
              <div className='shape2'></div>
              <div className='card-content'>
                  <h2 className='title'>LET'S GET YOUR PROJECT STARTED!</h2>
                  <div className='link-box' onClick={(e) => this.card(e)}><AwesomeButton
                        type="secondary"
                        // href="#"
                        // target="_blank"
                      >
                      CONTACT WITH US
                      </AwesomeButton>
                  </div>
              </div>
              <div className='form-content'>
                <Mainform lang='en'/>
              </div>
  
            </div>
          </Container>
          <footer className='main-footer'>
            <Container>
              <div className='content'>
                <Row>
                  <Col lg='4' md='6'>
                    <div className='logo-col'>
                      <div className='logobox'>
                      <Link to='/'><img src='https://cdn.lemoon-web.pl/wp-content/uploads/2021/03/logowhite.svg' alt='Lemoon Web'></img></Link>
                      </div>
                      <div className='textbox'>
                        <p className='text'>We are happy to create websites, shops and dedicated web applications, as well as advertising graphics. Get in touch today.</p>
                      </div>
                      <ul className='social'>
                        <li>
                          <a href='https://pl-pl.facebook.com/lemoonweb/'>
                            {/* <span className='flaticon-facebook'></span> */}
                            <FontAwesomeIcon className='icons' icon={faFacebookSquare} />
                            </a>
                        </li>
                        <li>
                        <a href='https://pl.linkedin.com/company/lemoon-web-jakub-j%C3%B3%C5%BAwiak?trk=public_profile_experience-item_result-card_subtitle-click'>
                          {/* <span className='flaticon-linkedin'></span> */}
                          <FontAwesomeIcon className='icons' icon={faLinkedin} />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </Col>
                  <Col lg='4' md='6'>
                    <div className='links-col'>
                      <h3 className='title'>Menu</h3>
                      <Row>
                        <Col xs='6'>
                          <ul>
                            <li><Link to='/portfolio'>Portfolio</Link></li>
                            <li><Link to='/kariera'>Career</Link></li>
                            {/* <li><Link to='/blog'>Blog</Link></li> */}
                          </ul>
                        </Col>
                        <Col xs='6'>
                          <ul>
                            <li><Link to='/kontakt'>Contact</Link></li>
                            <li><Link to='/cookies'>Cookies</Link></li>
                            </ul>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <Col lg='4' md='6'>
                    <div className='contact-col'>
                      <h3 className='title'>Contact</h3>
                      <ul className='contact-info'>
                        <li className='address'>
                          {/* <FontAwesomeIcon className='icon' icon={faMapMarkerAlt} /> */}
                          <img className='icon' alt='location' src='https://cdn.lemoon-web.pl/wp-content/uploads/2021/05/maps-and-flags.png'></img>
                          {this.props.adres}
                        </li>
                        <li>
                          {/* <FontAwesomeIcon className='icon' icon={faPhoneAlt} /> */}
                          <img className='icon' alt='phone' src='https://cdn.lemoon-web.pl/wp-content/uploads/2021/05/call-1.png'></img>
                          <a href={'tel:' + this.props.telen.replace(/ /g, '')}>{this.props.telen}</a>
                        </li>
                        <li>
                          {/* <FontAwesomeIcon className='icon' icon={faEnvelope} /> */}
                          <img className='icon' alt='mail' src='https://cdn.lemoon-web.pl/wp-content/uploads/2021/05/envelope.png'></img>
                          <a href={'mailto:' + this.props.mail}>{this.props.mail}</a>
                        </li>
                      </ul>
                    </div>
                  </Col>
                  {/* <Col lg='3' md='6'>
                    <div className='newsletter-col'>
                      <h3 className='title'>Newsletter</h3>
                      <div className='newsletter-form'>
                        <form>
                          <input type='email' name='email' placeholder='Email address' required></input>
                          <button type='submit' className='form-button'>
                            <FontAwesomeIcon className='icon' icon={faEnvelope} />
                          </button>
                        </form>
                      </div>
                      <div className='textbox'>
                        <p className='text'>Sign up for our latest news & articles. We won't give you spam mails.</p>
                      </div>
                    </div>
                  </Col> */}
                </Row>
              </div>
            </Container>
          </footer>
          </div>
        );
      } else {
        return (
          <div className="footer-container">
          <Container>
            <div className='get-started'>
              <div className='shape1'></div>
              <div className='shape2'></div>
              <div className='card-content'>
                  <h2 className='title'>ROZPOCZNIJ SWÓJ PROJEKT!</h2>
                  <div className='link-box' onClick={(e) => this.card(e)}><AwesomeButton
                        type="secondary"
                        // href="#"
                        // target="_blank"
                      >
                      NAPISZ DO NAS
                      </AwesomeButton>
                  </div>
              </div>
              <div className='form-content'>
                <Mainform lang='pl'/>
              </div>
  
            </div>
          </Container>
          <footer className='main-footer'>
            <Container>
              <div className='content'>
                <Row>
                  <Col lg='4' md='6'>
                    <div className='logo-col'>
                      <div className='logobox'>
                      <Link to='/'><img src='https://cdn.lemoon-web.pl/wp-content/uploads/2021/03/logowhite.svg' alt='Lemoon Web Olsztyn'></img></Link>
                      </div>
                      <div className='textbox'>
                        <p className='text'>Z przyjemnością tworzymy strony internetowe Olsztyn, sklepy oraz dedykowane aplikacje webowe, a także grafikę reklamową w Olsztynie. Skontaktuj się już dziś.</p>
                      </div>
                      <ul className='social'>
                        <li>
                          <a href='https://pl-pl.facebook.com/lemoonweb/'>
                            {/* <span className='flaticon-facebook'></span> */}
                            <FontAwesomeIcon className='icons' icon={faFacebookSquare} />
                            </a>
                        </li>
                        <li>
                        <a href='https://pl.linkedin.com/company/lemoon-web-jakub-j%C3%B3%C5%BAwiak?trk=public_profile_experience-item_result-card_subtitle-click'>
                          {/* <span className='flaticon-linkedin'></span> */}
                          <FontAwesomeIcon className='icons' icon={faLinkedin} />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </Col>
                  <Col lg='4' md='6'>
                    <div className='links-col'>
                      <h3 className='title'>Menu</h3>
                      <Row>
                        <Col xs='6'>
                          <ul>
                            <li><Link to='/portfolio'>Portfolio</Link></li>
                            <li><Link to='/kariera'>Kariera</Link></li>
                            {/* <li><Link to='/blog'>Blog</Link></li> */}
                          </ul>
                        </Col>
                        <Col xs='6'>
                          <ul>
                            <li><Link to='/kontakt'>Kontakt</Link></li>
                            <li><Link to='/cookies'>Cookies</Link></li>
                            </ul>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <Col lg='4' md='6'>
                    <div className='contact-col'>
                      <h3 className='title'>Kontakt</h3>
                      <ul className='contact-info'>
                        <li className='address'>
                          {/* <FontAwesomeIcon className='icon' icon={faMapMarkerAlt} /> */}
                          <img className='icon' alt='lokalizacja' src='https://cdn.lemoon-web.pl/wp-content/uploads/2021/05/maps-and-flags.png'></img>
                          {this.props.adres}
                        </li>
                        <li>
                          {/* <FontAwesomeIcon className='icon' icon={faPhoneAlt} /> */}
                          <img className='icon' alt='telefon' src='https://cdn.lemoon-web.pl/wp-content/uploads/2021/05/call-1.png'></img>
                          <a href={'tel:' + this.props.telpl.replace(/ /g, '')}>{this.props.telpl}</a>
                        </li>
                        <li>
                          {/* <FontAwesomeIcon className='icon' icon={faEnvelope} /> */}
                          <img className='icon' alt='mail' src='https://cdn.lemoon-web.pl/wp-content/uploads/2021/05/envelope.png'></img>
                          <a href={'mailto:' + this.props.mail}>{this.props.mail}</a>
                        </li>
                      </ul>
                    </div>
                  </Col>
                  {/* <Col lg='3' md='6'>
                    <div className='newsletter-col'>
                      <h3 className='title'>Newsletter</h3>
                      <div className='newsletter-form'>
                        <form>
                          <input type='email' name='email' placeholder='Email address' required></input>
                          <button type='submit' className='form-button'>
                            <FontAwesomeIcon className='icon' icon={faEnvelope} />
                          </button>
                        </form>
                      </div>
                      <div className='textbox'>
                        <p className='text'>Sign up for our latest news & articles. We won't give you spam mails.</p>
                      </div>
                    </div>
                  </Col> */}
                </Row>
              </div>
            </Container>
          </footer>
          </div>
        );
      }

    }
  }

export default SectionFoot;

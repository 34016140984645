import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import { Form, Input, Textarea } from 'formsy-react-components';
import { AwesomeButton, AwesomeButtonProgress } from "react-awesome-button";


class SectionForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = { send: false, valid: false, token:''}
    this.sendEmail = this.sendEmail.bind(this);
    this.disableButton = this.disableButton.bind(this);
    this.enableButton = this.enableButton.bind(this);
      
  }

  enableButton(){
    this.setState({ valid: true });
  }
  disableButton(){
    this.setState({ valid: false });
  }
  sendEmail(model) {

    model.security = 'asdd123';
    model.token  = this.state.token;
    fetch('https://cdn.lemoon-web.pl/emailsend.php', {
      crossDomain:true,
      mode: 'no-cors',
      headers: {'Content-Type':'application/json'},
      method: 'post',
      body: JSON.stringify(model),
    }).then(data => { 
      //this.updateToken();
      this.setState({ send: true });
      
      document.getElementById("formreset").reset();
      console.log(this.state.send);
    }); 
  }
  callback(){

  }
  render() {
    let ifsend = false;
    let lang = this.props.lang;
    if(lang === 'pl') {
    return (
      <Container>
        <div className='title-container'>
                <h2 className='title'>NAPISZ DO NAS.</h2>
              </div>
      <Form
      onSubmit={this.sendEmail}
      onValid={this.enableButton}
      onInvalid={this.disableButton}
      id="formreset"
      layout=''
      className="custom-classname-is-rendered"
      validateBeforeSubmit=''
      validatePristine=''
      ref=''>
        <Row>
          <Col md="6">
          <Input
          name="imie"
          id="imie"
          value=""
          // label=""
          type="text"
          placeholder="Imię"
          // help=""
          required 
        />
          </Col>
       <Col md="6">
       <Input
          name="email"
          id="email"
          validations="isEmail"
          value=""
          validationError="Błędny adres email"
          type="email"
          placeholder="Email"
          required
        />
       </Col>
        
        </Row>
        <Row>
          <Col md="6">
        <Input
          name="telefon"
          id="telefon"
          value=""
          validations="isNumeric"
          validationError="Błędny numer telefonu"
          type="phone"
          placeholder="Telefon"
        />
        </Col>
        <Col md="6">
        <Input
          name="temat"
          id="temat"
          value=""
          type="text"
          placeholder="Temat"
        />
        </Col>
        </Row>
        <Row>
          <Col>
          <Textarea
          name="wiadomosc"
          id="wiadomosc"
          value=""
          placeholder="Wiadomość"
         
        />
          </Col>

        </Row>
     
        <div className='submit-container'>
        <AwesomeButtonProgress
          type="submit"
          disabled={!this.state.valid}
          loadingLabel='Wysyłam'
          size="large"
          releaseDelay="5000"
          resultLabel='Wiadomość wysłana, dziękujemy!'
          action={(element, next) => {
            setTimeout(() => {
                console.log(element);

              next();
            }, 1000);
           
          
          }}
    
        >
          WYŚLIJ
        </AwesomeButtonProgress>
        </div>
        
          
        
      </Form>
      </Container>
    );
        } else {
          return (
            <Container>
              <div className='title-container'>
                      <h2 className='title'>WRITE TO US.</h2>
                    </div>
            <Form
             id="formreset"
            onSubmit={this.sendEmail}
            onValid={this.enableButton}
            onInvalid={this.disableButton}
            layout=''
            className="custom-classname-is-rendered"
            validateBeforeSubmit=''
            validatePristine=''
            ref=''>
              <Row>
                <Col md="6">
                <Input
                name="imie"
                id="imie"
                value=""
                // label=""
                type="text"
                placeholder="Name"
                // help=""
                required
              />
                </Col>
             <Col md="6">
             <Input
                name="email"
                id="email"
                validations="isEmail"
                validationError="This is not a  valid email"
                value=""
                type="email"
                placeholder="Email"
                required
              />
             </Col>
              
              </Row>
              <Row>
                <Col md="6">
              <Input
                name="telefon"
                id="telefon"
                value=""
                validations="isNumeric"
                validationError="This is not a valid phone number"
                type="phone"
                placeholder="Phone"
              />
              </Col>
              <Col md="6">
              <Input
                name="temat"
                id="temat"
                value=""
                type="text"
                placeholder="Subject"
              />
              </Col>
              </Row>
              <Row>
                <Col>
                <Textarea
                name="wiadomosc"
                id="wiadomosc"
                value=""
                placeholder="Message"
              
              />
                </Col>
              </Row>
           
              <div className='submit-container'>
              <AwesomeButtonProgress
                type="submit"
                disabled={!this.state.valid}
                loadingLabel='Sending'
                size="large"
                releaseDelay="5000"
                resultLabel='Email was sent, thank you!'
                action={(element, next) => {
                  setTimeout(() => {
                    next();
                  }, 1000);
                 
                
                }}
          
              >
                SEND
              </AwesomeButtonProgress>
              </div>
              
                
              
            </Form>
            </Container>
          );
        }
  }
};
export default SectionForm;
import React from "react"
import { Link } from "gatsby"
import Lang from "./lang"
import { Navbar, Nav, NavLink, Form, FormControl, Button, NavItem, Dropdown, NavDropdown } from "react-bootstrap"
import HoverDropdown from "../components/hoverdropdown"


class CustomNavbar extends React.Component {

  componentDidMount() {
    if (typeof window !== `undefined`) {
      let lastKnownScrollPosition = 0;

      function doSomething(scrollPos) {
      var element = document.getElementById('site-navbar');
        if (scrollPos > 150) {
          element.style.background = '#18181C';
        } else {
           element.style.background = 'transparent';
        }
    } 
    document.addEventListener('scroll', function(e) {
      lastKnownScrollPosition = window.scrollY;
          doSomething(lastKnownScrollPosition);
    });
    }
    
  }


  render() {
    let lang =  this.props.lang;
    const url = typeof window !== 'undefined' ? window.location.pathname : '';
    const slugstrony = 'website-development';
    let engcheck = url.split('/');
    let endurl = '';
    if(engcheck[1] === 'en'){
      let eng = true;
      if(url == '/en/strony-internetowe'){
        endurl = '/en/website-development';
      }
    } 
    if (lang === 'pl'){
    return (
      <>
        <Navbar variant="dark" expand="lg" id="site-navbar" expand='xl'>
          <div className='nav-logo'><Link to="/"><img src="https://cdn.lemoon-web.pl/wp-content/uploads/2021/05/logowhite-e1620727445939.png" width="130px" alt='Lemoon Web Olsztyn' /></Link></div>
          {/* <Navbar.Brand className='nav-logo'><Link to="/"><img src="https://cdn.lemoon-web.pl/wp-content/uploads/2021/05/logowhite-e1620727445939.png" width="130px" alt='Lemoon Web Olsztyn' /></Link></Navbar.Brand> */}
          {/* <Navbar.Toggle aria-controls="basic-navbar-nav" /> */}
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mx-auto">
              <Nav.Item>
                    <Nav.Link as={Link}  to="/" activeClassName='active' className="navitemlink">
                      <span className='navspan'>Home</span>
                    </Nav.Link>
              </Nav.Item>

        <HoverDropdown as={NavItem}>
        <Dropdown.Toggle as={NavLink}>OFERTA</Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item as={Link} to="/strony-internetowe" activeClassName='active' className="navitemlink">STRONY INTERNETOWE</Dropdown.Item>
                  <Dropdown.Item as={Link} to="/sklepy-internetowe" activeClassName='active' className="navitemlink">SKLEPY INTERNETOWE</Dropdown.Item>
                  <Dropdown.Item as={Link} to="/aplikacje-webowe" activeClassName='active' className="navitemlink">APLIKACJE WEBOWE</Dropdown.Item>
                  <Dropdown.Item as={Link} to="/grafika-komputerowa" activeClassName='active' className="navitemlink">GRAFIKA KOMPUTEROWA</Dropdown.Item>
                  <Dropdown.Item as={Link} to="/marketing-i-reklama" activeClassName='active' className="navitemlink">MARKETING I REKLAMA</Dropdown.Item>
                  <Dropdown.Item as={Link} to="/audyty" activeClassName='active' className="navitemlink">AUDYTY</Dropdown.Item>
                </Dropdown.Menu>
        </HoverDropdown>

        <Nav.Item>
              <Nav.Link as={Link}  to="/portfolio" activeClassName='active' className="navitemlink">
                <span className='navspan'>Portfolio</span>
              </Nav.Link>
        </Nav.Item>
        
              <Nav.Item>
                    <Nav.Link as={Link}  to="/kariera" activeClassName='active' className="navitemlink">
                      <span className='navspan'>Kariera</span>
                    </Nav.Link>
              </Nav.Item>

              {/* <Nav.Item>
                <Nav.Link as={Link}  to="/blog" activeClassName='active' className="navitemlink">
                  <span className='navspan'>Blog</span>
                </Nav.Link>
              </Nav.Item> */}
        
              <Nav.Item>
                    <Nav.Link as={Link}  to="/kontakt" activeClassName='active' className="navitemlink">
                      <span className='navspan'>Kontakt</span>
                    </Nav.Link>
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
          <Lang lang={lang}></Lang>
          <div className='link-box'>
            <div className='call-us'>
              <a href={'tel:' + this.props.telpl.replace(/ /g, '')} className='link'>
                <span className='icon'></span>
                <span className='text'>Zadzwoń</span>
                <span className='number'>{this.props.telpl}</span>
              </a>
            </div>
          </div>
        </Navbar>

        
      

      </>
    )
    }else{
      return (
        <>
       
          <Navbar variant="dark" expand="lg" id="site-navbar" >
            <Navbar.Brand as="a" className='nav-logo'><Link to="/en"><img src="https://cdn.lemoon-web.pl/wp-content/uploads/2021/05/logowhite-e1620727445939.png" width="130px" alt='Lemoon Web' /></Link></Navbar.Brand>
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="mx-auto">
                <Nav.Item>
                      <Nav.Link as={Link}  to="/en" activeClassName='active' className="navitemlink">
                        <span className='navspan'>Home</span>
                      </Nav.Link>
                </Nav.Item>
  
          <HoverDropdown as={NavItem}>
          <Dropdown.Toggle as={NavLink}>SERVICES</Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item as={Link} to="/en/website-development" activeClassName='active' className="navitemlink">WEBSITE DEVELOPMENT</Dropdown.Item>
                    <Dropdown.Item as={Link} to="/en/online-shops" activeClassName='active' className="navitemlink">ONLINE SHOPS</Dropdown.Item>
                    <Dropdown.Item as={Link} to="/en/web-apps" activeClassName='active' className="navitemlink">WEB APPS</Dropdown.Item>
                    <Dropdown.Item as={Link} to="/en/computer-graphics" activeClassName='active' className="navitemlink">COMPUTER GRAPHICS</Dropdown.Item>
                    <Dropdown.Item as={Link} to="/en/marketing-and-advertising" activeClassName='active' className="navitemlink">MARKETING AND ADVERTISING</Dropdown.Item>
                    <Dropdown.Item as={Link} to="/en/audits" activeClassName='active' className="navitemlink">AUDITS</Dropdown.Item>
                  </Dropdown.Menu>
          </HoverDropdown>
  
          <Nav.Item>
              <Nav.Link as={Link}  to="/en/portfolio" activeClassName='active' className="navitemlink">
                <span className='navspan'>Portfolio</span>
              </Nav.Link>
        </Nav.Item>
        
              <Nav.Item>
                    <Nav.Link as={Link}  to="/en/career" activeClassName='active' className="navitemlink">
                      <span className='navspan'>Career</span>
                    </Nav.Link>
              </Nav.Item>

              {/* <Nav.Item>
                <Nav.Link as={Link}  to="/en/blog" activeClassName='active' className="navitemlink">
                  <span className='navspan'>Blog</span>
                </Nav.Link>
              </Nav.Item> */}
        
              <Nav.Item>
                    <Nav.Link as={Link}  to="/en/contact" activeClassName='active' className="navitemlink">
                      <span className='navspan'>Contact</span>
                    </Nav.Link>
              </Nav.Item>
  
  
              </Nav>
            </Navbar.Collapse>
              <Lang lang={lang}></Lang>
            <div className='link-box'>
              <div className='call-us'>
                <a href={'tel:' + this.props.telen.replace(/ /g, '')} className='link'>
                  <span className='icon'></span>
                  <span className='text'>Call us</span>
                  <span className='number'>{this.props.telen}</span>
                </a>
              </div>
            </div>
          </Navbar>
        </>
      )
    }
  }
  
}
export default CustomNavbar;


import React from "react"
import { Link } from "gatsby"
import { slide as Menu } from 'react-burger-menu'
import { Navbar, Nav, NavLink, Form, FormControl, Button, NavItem, Dropdown, NavDropdown } from "react-bootstrap"
import $ from 'jquery';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons'

class Example extends React.Component {
  showSettings (event) {
    event.preventDefault();
  }
  componentDidMount() {
    window.$ = window.jQuery = require('jquery');
    $('.test').click(function(e){
      e.preventDefault();
      $('#toggle').stop().slideToggle( 400, function() {
        // Animation complete.
      });
    });
  }
  render () {
    // NOTE: You also need to provide styles, see https://github.com/negomi/react-burger-menu#styling
    let lang =  this.props.lang;
    if (lang === 'pl') {
      return (
        <Menu className={"sidebar-burger"}>
              <Nav className="mx-auto">
                <Nav.Item>
                      <Nav.Link as={Link}  to="/" activeClassName='active' className="navitemlink">
                        <span className='navspan'>Home</span>
                      </Nav.Link>
                </Nav.Item>
                <div className='nav-item'>
                  <div className="navitemlink nav-link test">OFERTA</div>
                </div>
                <div className="dropdown-oferta-side" id="toggle" style={{display:'none'}}>
                    <Nav.Link as={Link} to="/strony-internetowe" activeClassName='active' className="navitemlink">STRONY INTERNETOWE</Nav.Link>
                    <Nav.Link as={Link} to="/sklepy-internetowe" activeClassName='active' className="navitemlink">SKLEPY INTERNETOWE</Nav.Link>
                    <Nav.Link as={Link} to="/aplikacje-webowe" activeClassName='active' className="navitemlink">APLIKACJE WEBOWE</Nav.Link>
                    <Nav.Link as={Link} to="/grafika-komputerowa" activeClassName='active' className="navitemlink">GRAFIKA KOMPUTEROWA</Nav.Link>
                    <Nav.Link as={Link} to="/marketing-i-reklama" activeClassName='active' className="navitemlink">MARKETING I REKLAMA</Nav.Link>
                    
                    <Nav.Link as={Link} to="/audyty" activeClassName='active' className="navitemlink">AUDYTY</Nav.Link>
                </div>
  
          <Nav.Item>
                <Nav.Link as={Link}  to="/portfolio" activeClassName='active' className="navitemlink">
                  <span className='navspan'>Portfolio</span>
                </Nav.Link>
          </Nav.Item>
          
                <Nav.Item>
                      <Nav.Link as={Link}  to="/kariera" activeClassName='active' className="navitemlink">
                        <span className='navspan'>Kariera</span>
                      </Nav.Link>
                </Nav.Item>
  
                {/* <Nav.Item>
                  <Nav.Link as={Link}  to="/blog" activeClassName='active' className="navitemlink">
                    <span className='navspan'>Blog</span>
                  </Nav.Link>
                </Nav.Item> */}
          
                <Nav.Item>
                      <Nav.Link as={Link}  to="/kontakt" activeClassName='active' className="navitemlink">
                        <span className='navspan'>Kontakt</span>
                      </Nav.Link>
                </Nav.Item>
              </Nav>
              <div className='side-menu-sep'></div>
              <div className='side-menu-contact'>
                <a href="tel:+48730903403">biuro@lemoon-web.pl</a>
                <a href="mailto:biuro@lemoon-web.pl">+48 730 903 403</a>
              </div>
              <div className='side-menu-social'>
              <ul className='social'>
                        <li>
                          <a href='https://pl-pl.facebook.com/lemoonweb/'>
                            {/* <span className='flaticon-facebook'></span> */}
                            <FontAwesomeIcon className='icons' icon={faFacebookSquare} />
                            </a>
                        </li>
                        <li>
                        <a href='https://pl.linkedin.com/company/lemoon-web-jakub-j%C3%B3%C5%BAwiak?trk=public_profile_experience-item_result-card_subtitle-click'>
                          {/* <span className='flaticon-linkedin'></span> */}
                          <FontAwesomeIcon className='icons' icon={faLinkedin} />
                          </a>
                        </li>
                      </ul>
              </div>
        </Menu>
      );
    } else {
      return (
        <Menu className={"sidebar-burger"}>
              <Nav className="mx-auto">
                <Nav.Item>
                      <Nav.Link as={Link}  to="/" activeClassName='active' className="navitemlink">
                        <span className='navspan'>Home</span>
                      </Nav.Link>
                </Nav.Item>
                <div className='nav-item'>
                  <div className="navitemlink nav-link test">SERVICES</div>
                </div>
                <div className="dropdown-oferta-side" id="toggle" style={{display:'none'}}>
                    <Nav.Link as={Link} to="/en/website-development" activeClassName='active' className="navitemlink">WEBSITE DEVELOPMENT</Nav.Link>
                    <Nav.Link as={Link} to="/en/online-shops" activeClassName='active' className="navitemlink">ONLINE SHOPS</Nav.Link>
                    <Nav.Link as={Link} to="/en/web-apps" activeClassName='active' className="navitemlink">WEB APPS</Nav.Link>
                    <Nav.Link as={Link} to="/en/computer-graphics" activeClassName='active' className="navitemlink">COMPUTER GRAPHICS</Nav.Link>
                    <Nav.Link as={Link} to="/en/marketing-and-advertising" activeClassName='active' className="navitemlink">MARKETING AND ADVERTISING</Nav.Link>
                    <Nav.Link as={Link} to="/en/audits" activeClassName='active' className="navitemlink">AUDITS</Nav.Link>
                </div>
  
          <Nav.Item>
                <Nav.Link as={Link}  to="/en/portfolio" activeClassName='active' className="navitemlink">
                  <span className='navspan'>Portfolio</span>
                </Nav.Link>
          </Nav.Item>
          
                <Nav.Item>
                      <Nav.Link as={Link}  to="/en/career" activeClassName='active' className="navitemlink">
                        <span className='navspan'>Career</span>
                      </Nav.Link>
                </Nav.Item>
  
                {/* <Nav.Item>
                  <Nav.Link as={Link}  to="/en/blog" activeClassName='active' className="navitemlink">
                    <span className='navspan'>Blog</span>
                  </Nav.Link>
                </Nav.Item> */}
          
                <Nav.Item>
                      <Nav.Link as={Link}  to="/en/contact" activeClassName='active' className="navitemlink">
                        <span className='navspan'>Contact</span>
                      </Nav.Link>
                </Nav.Item>
              </Nav>
              <div className='side-menu-sep'></div>
              <div className='side-menu-contact'>
                <a href="tel:+48730903403">biuro@lemoon-web.pl</a>
                <a href="mailto:biuro@lemoon-web.pl">+48 730 903 403</a>
              </div>
              <div className='side-menu-social'>
              <ul className='social'>
                        <li>
                          <a href='https://pl-pl.facebook.com/lemoonweb/'>
                            {/* <span className='flaticon-facebook'></span> */}
                            <FontAwesomeIcon className='icons' icon={faFacebookSquare} />
                            </a>
                        </li>
                        <li>
                        <a href='https://pl.linkedin.com/company/lemoon-web-jakub-j%C3%B3%C5%BAwiak?trk=public_profile_experience-item_result-card_subtitle-click'>
                          {/* <span className='flaticon-linkedin'></span> */}
                          <FontAwesomeIcon className='icons' icon={faLinkedin} />
                          </a>
                        </li>
                      </ul>
              </div>
        </Menu>
      );
    }
    
  }
}
export default Example;


import React from "react"
import { Link } from "gatsby"
function LanguageSelector({ classes, lang, location, className }) {
    const url = typeof window !== 'undefined' ? window.location.pathname : '';
    //const slugstrony = 'website-development';
    let engcheck = url.split('/');
    let engurl = '';
    let plurl = '';
      
      if(url === '/strony-internetowe' || url === '/strony-internetowe/'){
        engurl = '/en/website-development';
      }
     else if(url === '/en/website-development' || url === '/en/website-development/'){
        plurl = '/strony-internetowe';
      }
      else if(url === '/sklepy-internetowe' || url === '/sklepy-internetowe/'){
        engurl = '/en/online-shops';
      }
      
      else if(url === '/en/online-shops' || url === '/en/online-shops/'){
        plurl = '/sklepy-internetowe';
      }
      else if(url === '/grafika-komputerowa' || url === '/grafika-komputerowa/'){
        engurl = '/en/computer-graphics';
      }
      else  if(url === '/en/computer-graphics' || url === '/en/computer-graphics/'){
        plurl = '/grafika-komputerowa';
      }
      else  if(url === '/marketing-i-reklama' || url === '/marketing-i-reklama/'){
        engurl = '/en/marketing-and-advertising';
      }
      else if(url === '/en/marketing-and-advertising' || url === '/en/marketing-and-advertising/'){
        plurl = '/marketing-i-reklama';
      }
      else  if(url === '/aplikacje-webowe' || url === '/aplikacje-webowe/'){
        engurl = '/en/web-apps';
      }
      else  if(url === '/en/web-apps' || url === '/en/web-apps/'){
        plurl = '/aplikacje-webowe';
      }
      else if(url === '/audyty' || url === '/audyty/'){
        engurl = '/en/audits';
      }
      else if(url === '/en/audits' || url === '/en/audits/'){
        plurl = '/audyty';
      }

      else if(url === '/portfolio'){
        engurl = '/en/portfolio';
      }
      else if(url === '/en/portfolio'){
        plurl = '/portfolio';
      }

      else if(url === '/kariera' || url === '/kariera/'){
        engurl = '/en/career';
      }
      else if(url === '/en/career' || url === '/en/career/'){
        plurl = '/kariera';
      }

      else if(url === '/blog'){
        engurl = '/en/blog';
      }
      else if(url === '/en/blog'){
        plurl = '/blog';
      }

      else if(url === '/kontakt' || url === '/kontakt/'){
        engurl = '/en/contact';
      }
      else if(url === '/en/contact' || url === '/en/contact/'){
        plurl = '/kontakt';
      }else if(engcheck[1] === 'blog'){
        engurl = '/en/blog';
        plurl = '/blog';
      }else if(engcheck[2] === 'blog'){
        plurl = '/blog';
        engurl = '/en/blog';
      }else{
      if(lang === 'pl'){
        
        plurl = url;
        engurl = '/en' + url;
      }else if(lang === 'en'){
      
      plurl = url.replace("/" + lang + "/", "/")
      engurl = url;

    }
  }
    if(url === '/en'){
    return (
      <div className='flag-container'>
            
      <Link className={className} to={`/en`}>
        <img className='flag' alt='english flag' src='https://cdn.lemoon-web.pl/wp-content/uploads/2021/03/united-kingdom.png'></img>
      </Link>
      <Link
      className={className}
      to={`/`}
      >
        <img className='flag' alt='flaga polska' src='https://cdn.lemoon-web.pl/wp-content/uploads/2021/03/poland.png'></img>
      </Link>
      </div>
    )
    }else if(url === '/'){
        return (
          <div className='flag-container'>
         
          <Link className={className} to={`/en`}>
          <img className='flag' alt='english flag' src='https://cdn.lemoon-web.pl/wp-content/uploads/2021/03/united-kingdom.png'></img>
          </Link>
          <Link
          className={className}
          to={`${url}`}
        >
          <img className='flag' alt='flaga polska' src='https://cdn.lemoon-web.pl/wp-content/uploads/2021/03/poland.png'></img>
        </Link>
        </div>
        )
    }else{
        return (
          <div className='flag-container'>
          <Link className={className} to={engurl}>
          <img className='flag' alt='english flag' src='https://cdn.lemoon-web.pl/wp-content/uploads/2021/03/united-kingdom.png'></img>
          </Link>
          <Link
          className={className}
          to={plurl}
        >
          <img className='flag' alt='flaga polska' src='https://cdn.lemoon-web.pl/wp-content/uploads/2021/03/poland.png'></img>
        </Link>
        </div>
        )
    }
  }

export default LanguageSelector